import { UAT_STORAGE_KEY } from '../constants'
import { isBrowser } from './common'

export const storeUserAccessToken = (accessToken: string): boolean => {
  if (!isBrowser()) {
    return false
  }
  try {
    window.localStorage.setItem(UAT_STORAGE_KEY, accessToken)
    return true
  } catch (error) {
    return false
  }
}

export const getUserAccessToken = (): string | null => {
  if (!isBrowser()) {
    return null
  }
  return window.localStorage.getItem(UAT_STORAGE_KEY)
}

export const removeUserAccessToken = (): boolean => {
  if (!isBrowser()) {
    return false
  }
  try {
    window.localStorage.removeItem(UAT_STORAGE_KEY)
    return true
  } catch (error) {
    return false
  }
}

export const isUserLoggedIn = (): boolean => {
  return !!getUserAccessToken()
}
