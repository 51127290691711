import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react'

import {
  isUserLoggedIn,
  getUserAccessToken,
  storeUserAccessToken,
  removeUserAccessToken,
} from '../utils/auth'

interface AuthContextProps {
  isLoggedIn: boolean
  pastLoggedIn: boolean
  storeToken: (accessToken: string) => boolean
  deleteToken: () => boolean
  accessToken?: string
}

export const AuthContext = createContext<AuthContextProps>({
  isLoggedIn: false,
  pastLoggedIn: false,
  storeToken: () => false,
  deleteToken: () => false,
  accessToken: undefined,
})

type Props = {
  children?: React.ReactNode
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string>()
  const [isLoggedIn, setIsLoggedIn] = useState(isUserLoggedIn())

  useEffect(() => {
    setIsLoggedIn(isUserLoggedIn())
    setAccessToken(getUserAccessToken() || undefined)
  }, [])

  const pastLoggedIn = useRef(isLoggedIn)
  useEffect(() => {
    // login/logout
    pastLoggedIn.current = isLoggedIn
  }, [isLoggedIn])

  const storeToken = useCallback((accessToken: string) => {
    const loggedIn = storeUserAccessToken(accessToken)
    setIsLoggedIn(loggedIn)
    setAccessToken(accessToken)
    return loggedIn
  }, [])

  const deleteToken = useCallback(() => {
    const loggedOut = removeUserAccessToken()
    setIsLoggedIn(!loggedOut)
    setAccessToken(undefined)
    return loggedOut
  }, [])

  const value = useMemo(() => ({
    isLoggedIn,
    pastLoggedIn: pastLoggedIn.current,
    storeToken,
    deleteToken,
    accessToken,
  }), [isLoggedIn, storeToken, deleteToken, accessToken])

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextProps => useContext(AuthContext)
