import * as React from 'react'
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getUserAccessToken } from '../utils/auth'

const httpLink = createHttpLink({
  uri: process.env.GATSBY_API_ROOT,
})

const authLink = setContext((_, previousContext) => {
  const token = getUserAccessToken()
  if (!token) return previousContext
  return {
    headers: {
      ...(previousContext.headers as Headers),
      authorization: `JWT ${token}`
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

type Props = {
  children?: React.ReactNode
}

const Apollo: React.FC<Props> = ({ children }) => (
  <ApolloProvider client={client}>
    {children}
  </ApolloProvider>
)

export default Apollo
