import { Site } from './types'

export const UAT_STORAGE_KEY = 'userAccessToken'

export const DESKTOP_MIN_WIDTH = 1024
export const MOBILE_BREAKPOINT = `${DESKTOP_MIN_WIDTH}px`

export const REGISTER_USER_AGREEMENT =
`Osobne podatke koje nam dajete na raspolaganje prikupljamo, obrađujemo ili koristimo kako
 bismo odgovorili na Vaše upite ili Vam osigurali ostvarivanje vaših prava
 temeljem zakonskih propisa. <br/><br/>
Prikupljanje, obrada i korištenje osobnih podataka kao i prosljeđivanje istih ovlaštenim
 državnim institucijama i ustanovama uslijedit će samo u okviru važećih zakona Opće uredbe o
 zaštiti osobnih podataka, odnosno ako smo to dužni učiniti na temelju sudske odluke.
<br/><br/>
Vaši osobni podaci neće se davati trećim osobama niti se na drugi način stavljati na tržište.`

// eslint-disable-next-line
export const FOOTER_TAGLINE = 'Omogućavamo freelancerima i malim poduzetnicima efikasnije poslovanje.'

export const accountTabs = [
  {
    title: 'Moj profil',
    url: '/account/profile/'
  },
  {
    title: 'Tvrtka',
    url: '/account/company/'
  },
  {
    title: 'Bankovni računi',
    url: '/account/bank-accounts/'
  },
  {
    title: 'Pravni podatci',
    url: '/account/legal-data/'
  },
  {
    title: 'Prom. lozinke',
    url: '/account/change-password/'
  },
]

export const ROUTES = {
  HOME: '/',
  NOT_FOUND: '/404/',
  INTERNAL_ERROR: '/500/',

  // Account
  LOGIN: '/account/login/',
  LOGOUT: '/account/logout/',
  REGISTER: '/account/register/',
  REGISTER_SUCCESS: '/account/register-success/',
  PROFILE: '/account/profile/',
  COMPANY: '/account/company/',
  BANK_ACCOUNTS: '/account/bank-accounts/',
  LEGAL_DATA: '/account/legal-data/',
  CHANGE_PASSWORD: '/account/change-password/',
  ONBOARDING: '/account/onboarding/',
  RESET_PASSWORD_REQUEST: '/account/reset-password/request/',
  RESET_PASSWORD: (userId: string, verificationToken: string) => (
    `/account/reset-password/${userId}/${verificationToken}/`
  ),
  ACTIVATE: (userId: string, activationCode: string) => (
    `/account/activate/${userId}/${activationCode}/`
  ),

  // Counter parties
  COUNTER_PARTY_EDIT: (id: string) => `/counter-parties/${id}/edit/`,
  COUNTER_PARTY_ADD: '/counter-parties/add/',
  COUNTER_PARTY_LIST: '/counter-parties/',

  // Invoices
  INVOICE_LIST: '/invoices/',
  INVOICE_ADD: '/invoices/add/',
  INVOICE_EDIT: (id: string) => `/invoices/${id}/edit/`,

  // Marketing
  FAQ: '/faq/',
  PRICING: '/pricing/',
  ROADMAP: '/roadmap/',
  CONTACT: '/contact/',

  // Legal
  TERMS: '/terms-of-use/',
  PRIVACY: '/privacy-policy/',

  // Blog
  BLOG_LIST: '/blog/',
  BLOG_DETAIL: (slug: string) => `/blog/${slug}/`,
}

export const siteMapExcludePaths = [
  ROUTES.INVOICE_LIST,
  ROUTES.INVOICE_ADD,
  ROUTES.INVOICE_EDIT('[invoiceId]'),
  ROUTES.REGISTER_SUCCESS,
  ROUTES.LOGOUT,
  ROUTES.COUNTER_PARTY_LIST,
  ROUTES.COUNTER_PARTY_ADD,
  ROUTES.COUNTER_PARTY_EDIT('[counterPartyId]'),
  ROUTES.PROFILE,
  ROUTES.COMPANY,
  ROUTES.BANK_ACCOUNTS,
  ROUTES.LEGAL_DATA,
  ROUTES.CHANGE_PASSWORD,
  ROUTES.ONBOARDING,
  ROUTES.RESET_PASSWORD_REQUEST,
  ROUTES.RESET_PASSWORD('[userId]', '[verificationToken]'),
  ROUTES.ACTIVATE('[userId]', '[activationCode]'),
]

export const PAGES_SEO = {
  HOME: (site: Site) => ({
    title: site.siteMetadata.productName,
    // eslint-disable-next-line max-len
    description: 'Kreirajte račune brzo i jednostavno. Ako ste freelancer ili mali poduzetnik Fakturiraj je dizajniran za vas. Registrirajte se besplatno i započnite koristiti odmah.'
  }),
  PRICING: {
    title: 'Paketi i cijene',
    // eslint-disable-next-line max-len
    description: 'Pregledajte pakete na Fakturiraj.com: besplatni Lajt idealan za paušalce i mikro firme ili Pro za napredne opcije. Odaberite idealno rješenje za brzo fakturiranje.'
  },
  PRIVACY_POLICY: {
    title: 'Politika privatnosti',
    // eslint-disable-next-line max-len
    description: 'Pročitajte našu politiku privatnosti kako biste saznali kako štitimo vaše osobne podatke i čuvamo vašu privatnost prilikom korištenja naših usluga.'
  },
  ROADMAP: {
    title: 'Roadmap',
    // eslint-disable-next-line max-len
    description: 'Otkrijte što budućnost nosi za naše korisnike uz naš roadmap. Planirajte zajedno s nama i budite u toku s nadolazećim funkcionalnostima i poboljšanjima.'
  },
  TERMS_OF_USE: {
    title: 'Uvjeti korištenja',
    // eslint-disable-next-line max-len
    description: 'Upoznajte se s uvjetima korištenja kako biste osigurali informirano i sigurno iskustvo korištenja naših usluga. Pročitajte detaljno naše smjernice i pravila.'
  },
  FAQ: {
    title: 'Često postavljana pitanja',
    // eslint-disable-next-line max-len
    description: 'Pronađite odgovore na često postavljana pitanja i dobijte brze informacije o našim uslugama na stranici FAQ. Riješite svoje dileme o korištenju aplikacije.'
  },
  CONTACT: {
    title: 'Kontakt forma',
    // eslint-disable-next-line max-len
    description: 'Kontaktirajte nas za sve upite ili podršku. Tu smo da vam pomognemo s bilo kakvim upitima vezanim za naše usluge. Dostupni smo putem emaila ili kontakt formulara.'
  },
  NOT_FOUND: {
    title: '404: Stranica ne postoji',
    description: 'Stranica nije pronađena.'
  },
  SERVER_ERROR: {
    title: '500: Greška na serveru',
    description: 'Greška na serveru'
  },
  ACTIVATE_ACCOUNT: {
    title: 'Aktivacija korisničkog računa',
    description: 'Aktivacija korisničkog računa'
  },
  RESET_PASSWORD: {
    title: 'Reset izgubljene lozinke',
    description: 'Reset izgubljene lozinke'
  },
  RESET_PASSWORD_REQUEST: {
    title: 'Zahtjev za reset lozinke',
    description: 'Zahtjev za reset lozinke'
  },
  LOGIN: {
    title: 'Prijava',
    // eslint-disable-next-line max-len
    description: 'Prijavite se za pristup svom korisničkom računu i otključajte sve mogućnosti naše platforme. Brz i siguran login čeka na vas za neometano korištenje usluga.'
  },
  REGISTER: {
    title: 'Registracija',
    // eslint-disable-next-line max-len
    description: 'Registrirajte se danas i započnite koristiti naše usluge bez odgode. Postupak je brz i jednostavan, osiguravajući vam pristup svim našim funkcionalnostima.'
  },
  REGISTER_SUCCESS: {
    title: 'Uspješna registracija',
    // eslint-disable-next-line max-len
    description: 'Pronađite aktivacijski email u vašem inboxu. Klikom na poveznicu aktivirajte svoj korisnički račun i započnite s korištenjem naše platforme.'
  },
  BLOG: (site: Site) => ({
    title: `Blog | ${site.siteMetadata.productName}`,
    // eslint-disable-next-line max-len
    description: 'Cilj nam je da na ovim stranicama pronađete ćete odgovore na sva pitanja vezana za vođenje obrta ili firme.'
  }),
}
