import { format } from 'date-fns'

export const isBrowser = (): boolean => typeof window !== 'undefined'

const parseNumberByFirstNonZero = (value: number): string => {
  const numberStr = value.toString()

  if (!numberStr.includes('.')) return numberStr

  if (numberStr[0] !== '0') return value.toFixed(2)

  const hasMoreThan3DecimalPlaces = numberStr.split('.')[1].length > 2

  if (hasMoreThan3DecimalPlaces) return `${value.toFixed(2)}...`

  return numberStr
}

export const parseDecimal = (value: string | undefined | number): string => {
  let numValue: number | undefined
  if (typeof value === 'string') {
    numValue = parseFloat(value)
  } else if (typeof value === 'number') {
    numValue = value
  }

  if (typeof numValue === 'number') return parseNumberByFirstNonZero(numValue)

  return '-'
}

export const formatMoney = (value: string | number, currency = ''): string => {
  const numValue = typeof value === 'string' ? parseFloat(value) : value
  let strValue = '-'
  if (numValue !== null) {
    strValue = numValue >= 0 ? numValue.toFixed(2) : `(${Math.abs(numValue).toFixed(2)})`
  }

  let currencySymbol = ''
  if (currency?.toLowerCase() === 'eur') currencySymbol = '€'
  else if (currency?.toLowerCase() === 'usd') currencySymbol = '$'

  return `${currencySymbol}${strValue}${!currencySymbol ? currency : ''}`
}

export const formatDate = (value: Date | string): string => {
  return format(value, 'dd.MM.yyyy.')
}

export const initials = (value: string, limit: number): string => {
  return value.toLowerCase().trim().replaceAll(
    '-', ' '
  ).replaceAll(
    ',', ' '
  ).replaceAll(
    'and', ''
  ).replaceAll(
    '&', ''
  ).split(
    ' '
  ).filter(x => x).map(x => x[0]).slice(0, limit).join('').toUpperCase()
}

export const isValidOIB = (oib: string): boolean => {
  if (oib.length !== 11) {
    return false
  }
  if (!/^\d+$/.test(oib)) {
    return false
  }
  const meat = oib.substring(0, 10)
  const control = parseInt(oib.charAt(10))
  let previousRes = 10
  for (let i = 0; i < meat.length; i++) {
    const val = parseInt(meat.charAt(i)) + previousRes
    previousRes = ((val % 10 === 0 ? 10 : val % 10) * 2) % 11
  }
  const res = previousRes === 1 ? 0 : 11 - previousRes
  return res === control
}
