import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

type MessageContent = JSX.Element | string | undefined
type MessageSeverity = 'success' | 'error' | 'warning' | 'info'

type Nessage = {
  content: MessageContent
  severity: MessageSeverity
}

interface ProviderValue {
  message?: Nessage
  showMessage: (message?: JSX.Element | string, severity?: MessageSeverity) => void
  hideMessage: () => void
}

export const GlobalMessage = createContext<ProviderValue>({
  message: undefined,
  showMessage: () => undefined,
  hideMessage: () => undefined,
})

type Props = {
  children?: React.ReactNode
}

export const GlobalMessageProvider: React.FC<Props> = ({
  children,
}) => {
  const [message, setMessage] = useState<Nessage>()

  const showMessage = useCallback((
    content: MessageContent, severity: MessageSeverity = 'success'
  ) => {
    setMessage({ content, severity })
    setTimeout(() => {
      setMessage(undefined)
    }, 5000)
  }, [])

  const hideMessage = useCallback(() => {
    setMessage(undefined)
  }, [])

  const value = useMemo(() => (
    { message, showMessage, hideMessage }), [message, showMessage, hideMessage]
  )

  return (
    <GlobalMessage.Provider value={value}>
      {children}
    </GlobalMessage.Provider>
  )
}

export const useGlobalMessage = (): ProviderValue => useContext(GlobalMessage)
