import * as React from 'react'

import ApolloProvider from './Apollo'
import { AuthProvider } from './Auth'
import { GlobalMessageProvider } from './GlobalMessage'

type Props = {
  children?: React.ReactNode
}

export const AppProvider: React.FC<Props> = ({ children }) => (
  <ApolloProvider>
    <AuthProvider>
      <GlobalMessageProvider>
        { children }
      </GlobalMessageProvider>
    </AuthProvider>
  </ApolloProvider>
)
